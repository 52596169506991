$base-font-color: rgb(132, 132, 132);
// $hover-color: rgb(192, 130, 250);
// $hover-color: #c29ee1;
$hover-color: #e19eb0;

.landing {
  text-align: center;
  // min-height: 100vh;
}

a {
  text-decoration: none;
  color: $base-font-color;

  &:hover {
    color: $hover-color;
    font-weight: 900;
    transition: .75s;
  }
}

.navigation-links {
  display: flex;
  justify-content: end;
  text-transform: uppercase;
  font-size: calc(10px + 2vmin);
  font-family: Montserrat;

  li {
    display: inline;
    list-style: none;
    padding: 10px 20px 0 20px;

    &:last-child {
      padding-right: 100px;
    }
  }
}

.top-section {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 10vh;
  font-size: calc(10px + 2vmin);
  font-family: Montserrat;
  min-height: calc(100vh - 150px);

  #bannerImg{
    max-width: 33%;
  }

  h1 {
    // text-transform: uppercase;
    text-transform: lowercase;
    font-weight: 100;
    letter-spacing: 3px;
    font-size: calc(10px + 6vmin);
    color: $base-font-color;
    font-family: Shrikhand;
  }
  
  .social-links {
    display: block;
    
    svg{
      padding: 0px 20px 0px 20px;
      color: $base-font-color;

      &:hover {
          color: $hover-color;
          transition: .75s;
        }
    }

    .tooltips {
      font-size: 12px;
      background-color: $base-font-color;
    }
  }
}

@media (max-width: 768px) {
  .navigation-links {
      justify-content: center;
      padding-inline-end: 40px;

      li {
        padding: 20px;

        &:last-child {
            padding-right: 20px;
          }
      }
  }
  .top-section{
    flex-direction: column-reverse;

    #bannerImg {
      max-width: 100%;
    }
    
  }
}
